import React from "react";
import HomeScreen from "./HomeScreen";
import JournalState from "./context/JournalState";

const App = () => {
  return (
    <JournalState>
      <HomeScreen />
    </JournalState>
  );
};

export default App;
