import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import reportWebVitals from "reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "index.css";
import "App.css";

ReactDOM.render(
  <App />,

  
  document.getElementById("root"),

  
);


reportWebVitals();
