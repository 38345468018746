import {
  ADD_THOUGHT,
} from "./types";

const journalReducer = (state, action) => {
  switch (action.type) {
    case ADD_THOUGHT:
      return {
        ...state,
        thoughts: state.thoughts + action.payload,
      };

    default:
      return state;
  }
};

export default journalReducer;
