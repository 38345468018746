import React, { useState, useContext, useEffect, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Alert } from "react-bootstrap";
import IntroScreen from "IntroScreen";
import Popover from "@material-ui/core/Popover";
import { Drawer, Snackbar } from "@material-ui/core";
import { Fullscreen as FullscreenIcon, Brightness4 as DarkModeIcon } from "@material-ui/icons";
import "./HomeScreen.css";

import Editor from "components/Editor";
import JournalContext from "context/journalContext";

const HomeScreen = () => {
  const journalContext = useContext(JournalContext);

  const {
    zenMode,
    modal,
    thoughts,
  } = journalContext;

  const [thoughtsOpen, setThoughtsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editable, setEditable] = useState(false);
  const [alertEditable, setAlertEditable] = useState(false);
  const [copy, setCopy] = useState(false);
  const [desc, setDesc] = useState(``);
  const ref = useRef();

  const handle = useFullScreenHandle();
  const [screenShow, setScreenShow] = useState(true);
  const refText = useRef();

  const handleCopy = () => {
    console.log("refText?.current", refText?.current);
    copyWithStyle(refText?.current);
    setCopy(true);
  };

  const copyWithStyle = (element) => {
    const doc = document;
    const text = element;
    let range;
    let selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElement(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();

      range = doc.createRange();
      range.selectNodeContents(text);

      selection.removeAllRanges();
      selection.addRange(range);
    }

    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };

  const handelEditable = () => {
    setEditable(!editable);

    if (editable) {
      setAlertEditable(false);
    } else {
      setAlertEditable(true);
    }
  };

  const toggleDarkMode = () => {
    document.body.classList.toggle('dark-mode');
    const fixedButtons = document.querySelector('.fixed-buttons');
    fixedButtons.classList.toggle('dark-mode');
  };

  const handleToggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        alert(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("dismissedHelp")) {
      // Code for handling dismissed help
    }

    function captureKey(event) {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 79) {
        setThoughtsOpen(!thoughtsOpen);
        event.preventDefault();
        return false;
      }
    }

    window.addEventListener("keydown", captureKey);

    return () => {
      window.removeEventListener("keydown", captureKey);
    };
  }, [thoughtsOpen]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [thoughtSaved, setThoughtSaved] = useState(false);
  if (thoughtSaved) {
    window.setTimeout(() => setThoughtSaved(false), 2000);
  }
  const open = Boolean(anchorEl);

  useEffect(() => {
    setTimeout(() => {
      if (copy) setCopy(false);
    }, 4000);
  }, [copy]);

  return (
    <FullScreen handle={handle} className="full-screen-home">
      <div>
        {!screenShow && !zenMode && (
          <>
            <div
              className={`alert-container ${thoughtSaved && modal && "show"}`}
            >
              <Alert variant="secondary"></Alert>
            </div>

            <Editor
              type="tab1"
              key={editable.toString()}
              setThoughtSaved={setThoughtSaved}
              editable={true}
              setDesc={setDesc}
              thoughts={thoughts}
              copy={copy}
              handleCopy={handleCopy}
              handelEditable={handelEditable}
            />

            <Snackbar
              open={alertEditable}
              autoHideDuration={3000}
              onClose={() => setAlertEditable(false)}
            />

            <div
              ref={refText}
              style={{ position: "absolute", zIndex: "-30", opacity: 0 }}
            >
              <div
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: thoughts }}
              />
            </div>
            
            <div ref={ref}>
              <Drawer
                className="drawer"
                container={ref.current}
                anchor="right"
                open={thoughtsOpen}
                onClose={() => setThoughtsOpen(false)}
              >
                <div className="thoughts-sidebar h-100 d-flex flex-column position-relative">
                  {/* Existing Editor component */}
                  <Editor
                    type="tab2"
                    key={editable.toString()}
                    setThoughtSaved={setThoughtSaved}
                    editable={editable}
                    setDesc={setDesc}
                    desc={desc}
                    thoughts={thoughts}
                    copy={copy}
                    handleCopy={handleCopy}
                    handelEditable={handelEditable}
                    spellcheck="false"
                  />
                  
                  {/* New buttons column */}
                  <div className="fixed-buttons">
                    <button onClick={handleToggleFullScreen}><FullscreenIcon /></button>
                    <button onClick={toggleDarkMode}><DarkModeIcon /></button>
                    {/* <button onClick={handleCopy}><CopyIcon /></button> */}
                    {/* <button><ExportIcon /></button> */}
                  </div>
                </div>
              </Drawer>
            </div>

            <div>
              <Popover
                open={open}
                container={ref.current}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div></div>
              </Popover>
            </div>
          </>
        )}
      </div>
      {screenShow && <IntroScreen onExit={() => setScreenShow(false)} />}
    </FullScreen>
  );
};

export default HomeScreen;
