import { useContext } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Heading from "@tiptap/extension-heading";
import Document from "@tiptap/extension-document";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import JournalContext from "../context/journalContext";
import "./editor.css";
// import { AutoScroll } from "./autoscroll";

const CustomHeading = Heading.extend({
  addKeyboardShortcuts() {
    return {
      "Mod-shift-h": () => this.editor.commands.toggleHeading({ level: 1 }),
      "Mod-shift-j": () => this.editor.commands.toggleHeading({ level: 2 }),
      // ... other shortcuts as needed
    };
  },
});

const CustomDocument = (journalContext, setFinish, setThoughtSaved) =>
  Document.extend({
    addKeyboardShortcuts() {
      return {
        "Mod-s": () => {
          const html = this.editor.getHTML();
          console.log(html);
          if (html === "<p></p>") return this.editor.commands.clearContent();
          setThoughtSaved(true);
          journalContext.addThoughts(html);
          return this.editor.commands.clearContent();
        },
        "Mod--": () => this.editor.commands.toggleBulletList(),
        "Mod-shift-o": () => this.editor.commands.blur(),
        "Mod-shift-s": () => this.editor.commands.toggleStrike(),
        "Mod-shift-p": () => {
          const url = window.prompt("... add an image with a link! (https://...)");

          if (url) {
            this.editor.chain().focus().setImage({ src: url }).run();
            this.editor.commands.focus("end");
          }
        },
        "Mod-shift-f": () => {
          // Functionality to toggle full screen
          if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((err) => {
              alert(`Error attempting to enable full-screen mode: ${err.message}`);
            });
          } else {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            }
          }
          return true;
        },
        "Mod-shift-d": () => {
          document.body.classList.toggle("dark-mode");
          return true;
        },
        "Mod-control-=": () => {
          let fontSize = getCurrentFontSize(this.editor);
          console.log(`Current font size: ${fontSize}px`);
          fontSize += 2;
          setFontSize(this.editor, fontSize);
          console.log(`Increased font size to: ${fontSize}px`);
          return true;
        },
        "Mod-control--": () => {
          let fontSize = getCurrentFontSize(this.editor);
          console.log(`Current font size: ${fontSize}px`);
          fontSize -= 2;
          setFontSize(this.editor, fontSize);
          console.log(`Decreased font size to: ${fontSize}px`);
          return true;
        },
      };
    },
  });

const getCurrentFontSize = (editor) => {
  const { from, to } = editor.state.selection;
  let fontSize = '16px'; // Default font size
  editor.state.doc.nodesBetween(from, to, (node) => {
    if (node.attrs.style) {
      const style = node.attrs.style;
      const match = style.match(/font-size:\s*(\d+px)/);
      if (match) {
        fontSize = match[1];
      }
    }
  });
  return parseInt(fontSize, 10);
};

const setFontSize = (editor, size) => {
  editor.chain().focus().setMark("textStyle", { fontSize: `${size}px` }).run();
};

const Editor = ({
  setFinish,
  setThoughtSaved,
  editable,
  setDesc,
  desc,
  thoughts,
  type,
}) => {
  const journalContext = useContext(JournalContext);

  const editor = useEditor({
    extensions: [
      Underline,
      StarterKit,
      CustomHeading,
      CustomDocument(journalContext, setFinish, setThoughtSaved),
      Placeholder.configure({
        placeholder: "",
      }),
      Image,
      TextStyle,
      // AutoScroll,
    ],
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setDesc(html);
    },
    content: type === "tab1" ? desc : thoughts,
    editorProps: {
      attributes: {
        class: "editor",
      },
    },
    autofocus: true,
    editable: editable,
  });

  return (
    <>
      <div className="ProseMirror-Container">
        <EditorContent editor={editor} spellCheck={false} />
      </div>
    </>
  );
};

export default Editor;
