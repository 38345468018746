import React, { useReducer } from "react";
import JournalContext from "./journalContext";
import journalReducer from "./journalReducer";
import {
  ADD_THOUGHT,
} from "./types";

const JournalState = (props) => {
  const initialState = {
    thoughts: "",
  };

  const [state, dispatch] = useReducer(journalReducer, initialState);
  const addThoughts = (thought) => {
    dispatch({ type: ADD_THOUGHT, payload: thought });
  };

  return (
    <JournalContext.Provider
      value={{
        thoughts: state.thoughts,
        addThoughts,
        modal: state.modal,
      }}
    >
      {props.children}
    </JournalContext.Provider>
  );
};

export default JournalState;
