import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import logo from "images/logo.png";
import "./IntroScreen.css";

const IntroScreen = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 576);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  const handleExit = () => {
    props.onExit();
  };

  return (
    <div className="intro-screen">
      <Image src={logo} className="logo" draggable={false} />
      <div className="paragraph1">
        <p>
          A minimal app for {!isMobile ? "meditative writing." : "meditative writing. Journal Flow was designed to work with a keyboard. Sorry mobile user, switch to your desktop to start writing!"}
        </p>
        {!isMobile && (
          <div className="button-row">
            <Button variant="light" className="custom-button commands-button" onClick={() => window.open("https://journal-flow.webflow.io/all-controls", "_blank")}>
              Controls
            </Button>
            <Button variant="light" className="custom-button commands-button" onClick={() => window.open("https://journal-flow.webflow.io/", "_blank")}>
              Demo
            </Button>
            <Button variant="poppy" className="custom-button start-button" onClick={handleExit}>
              Start
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntroScreen;
